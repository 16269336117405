// steelhouse variables
:root {
  --l: 50%;
  --text-color: black;
  --text-weight: 400;
  --box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.45);
  --brand-color-1: 65, 100%;
  --booking-brand-color-1: hsl(var(--brand-color-1), var(--l));
  --dimmed-booking-brand-color-1: hsl(var(--brand-color-1), calc(var(--l) - 10%));
  --dimmed-2-booking-brand-color-1: hsl(var(--brand-color-1), calc(var(--l) - 20%));
  --booking-brand-color-2: #262626;
  --booking-brand-color-3: #efefef;
}
@import 'app-common.scss';
@import 'app-grid.css';
