/*
  Hides Sitecore editor markup,
  if you run the app in connected mode while the Sitecore cookies
  are set to edit mode.
*/
.scChromeData,
.scpm {
  display: none !important;
}

/*
  Styles for default JSS error components
*/
.sc-jss-editing-error,
.sc-jss-placeholder-error {
  padding: 1em;
  background-color: lightyellow;
}

/* 
  Style for default content block
*/
.contentTitle {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

// Swiper styles
.swiper-slide {
  width: fit-content !important;
}
.swiper-button {
  display: none;
}
@media screen and (min-width: 1024px) {
  .swiper-scrollbar {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .swiper-button {
    display: flex;
    z-index: 10;
    cursor: pointer;
  }
  .image-slider1-button-prev,
  .image-slider2-button-prev,
  .image-eventCalendar-button-prev {
    opacity: 0.9;
    left: 0px;
  }
  .image-slider1-button-next,
  .image-slider2-button-next,
  .image-eventCalendar-button-next {
    opacity: 0.9;
    right: 0px;
  }
  .slider1-button-disabled,
  .slider2-button-disabled,
  .eventCalendar-button-disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

/* react-datePicker styles */
.datePickerWrapper {
  height: 693px !important;
}
.react-datepicker {
  padding-top: 1rem;
  border-radius: 0px !important;
}
.react-datepicker__header {
  padding: 4px !important;
  background-color: #fff !important;
  border-bottom: unset !important;
}
.react-datepicker__month-container {
  width: 100%;
  text-transform: capitalize;
}
.react-datepicker__month {
  margin: 0 !important;
}
.react-datepicker__current-month {
  font-size: 14px !important;
  padding: 1rem 0 !important;
}
.react-datepicker__week {
  font-size: 1rem !important;
  position: relative !important;
  z-index: 1 !important;
}

.react-datepicker__day-names {
  display: none;
}
.react-datepicker__week {
  display: flex;
  justify-content: center;
}
.react-datepicker__day-name {
  color: #4e5356 !important;
}
.react-datepicker__day {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 2.7rem !important;
  height: 2.7rem !important;
  margin: 0px !important;
  font-size: 1rem;
}
.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
  background-color: var(--booking-brand-color-3) !important;
  color: var(--booking-brand-color-2) !important;
  border-radius: 0px !important;
}

.react-datepicker__day--selected {
  background-color: #fff !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background-color: var(--booking-brand-color-2) !important;
  color: var(--booking-brand-color-1) !important;
  border-radius: 50% !important;
}
.react-datepicker__day--range-start::after,
.react-datepicker__day--range-end::after {
  content: '';
  position: absolute;
  top: 0;
  background-color: var(--booking-brand-color-3) !important;
  width: 2.7rem !important;
  height: 2.7rem !important;
  z-index: -1;
}
.react-datepicker__day--range-start::after {
  border-radius: 50% 0 0 50% !important;
}
.react-datepicker__day--range-end::after {
  border-radius: 0 50% 50% 0 !important;
}
.react-datepicker__day--outside-month {
  visibility: hidden !important;
  // height: 0px !important;
}
.react-datepicker__navigation {
  top: 1rem !important;
}
.react-datepicker__navigation--next {
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI0LjUgMTIuNUMyNC41IDE5LjEyNzQgMTkuMTI3NCAyNC41IDEyLjUgMjQuNUM1Ljg3MjU4IDI0LjUgMC41IDE5LjEyNzQgMC41IDEyLjVDMC41IDUuODcyNTggNS44NzI1OCAwLjUgMTIuNSAwLjVDMTkuMTI3NCAwLjUgMjQuNSA1Ljg3MjU4IDI0LjUgMTIuNVoiIHN0cm9rZT0iIzIxMjEyMSIvPgo8bGluZSB4MT0iMTguMTgxNiIgeTE9IjEyLjUiIHgyPSI1LjQ1NDM2IiB5Mj0iMTIuNSIgc3Ryb2tlPSIjMjEyMTIxIi8+CjxwYXRoIGQ9Ik0xNSAxNy4wNDU1QzE1IDE0LjUzNTEgMTcuMDM1MSAxMi41IDE5LjU0NTUgMTIuNSIgc3Ryb2tlPSIjMjEyMTIxIi8+CjxwYXRoIGQ9Ik0xOS41NDU1IDEyLjVDMTcuMDM1MSAxMi41IDE1IDEwLjQ2NDkgMTUgNy45NTQ1NSIgc3Ryb2tlPSIjMjEyMTIxIi8+Cjwvc3ZnPgo=')
    no-repeat !important;
  border: none !important;
  right: 12px !important;
  visibility: hidden;
}
.react-datepicker__navigation--previous {
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuNDk5OTk1IDEyLjVDMC40OTk5OTYgNS44NzI1OCA1Ljg3MjU4IDAuNDk5OTk4IDEyLjUgMC40OTk5OTlDMTkuMTI3NCAwLjQ5OTk5OSAyNC41IDUuODcyNTggMjQuNSAxMi41QzI0LjUgMTkuMTI3NCAxOS4xMjc0IDI0LjUgMTIuNSAyNC41QzUuODcyNTggMjQuNSAwLjQ5OTk5NSAxOS4xMjc0IDAuNDk5OTk1IDEyLjVaIiBzdHJva2U9IiMyMTIxMjEiLz4KPGxpbmUgeDE9IjYuODE4MzYiIHkxPSIxMi41IiB4Mj0iMTkuNTQ1NiIgeTI9IjEyLjUiIHN0cm9rZT0iIzIxMjEyMSIvPgo8cGF0aCBkPSJNMTAgNy45NTQ1NUMxMCAxMC40NjQ5IDcuOTY0OTMgMTIuNSA1LjQ1NDU1IDEyLjUiIHN0cm9rZT0iIzIxMjEyMSIvPgo8cGF0aCBkPSJNNS40NTQ1NSAxMi41QzcuOTY0OTMgMTIuNSAxMCAxNC41MzUxIDEwIDE3LjA0NTUiIHN0cm9rZT0iIzIxMjEyMSIvPgo8L3N2Zz4K')
    no-repeat !important;
  border: none !important;
  left: 12px !important;
  visibility: hidden;
}
span.react-datepicker__navigation-icon {
  display: none !important;
}

.lock-scroll {
  position: fixed;
}

.overflow-hidden {
  overflow: hidden;
}

.datepickerEditEventCalendar {
  .react-datepicker__day-names {
    gap: 28px;
  }
}

.datepickerGroupReservationContainer {
  .react-datepicker__navigation--next,
  .react-datepicker__navigation--previous {
    visibility: visible;
  }
  .react-datepicker__navigation {
    top: 2rem !important;
  }
  .react-datepicker__day:hover {
    background-color: #fff !important;
  }
  .react-datepicker__day--selected {
    background-color: var(--booking-brand-color-2) !important;
    color: var(--booking-brand-color-1) !important;
    border-radius: 50% !important;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: #fff !important;
    color: #000 !important;
  }
  .react-datepicker__day--selected::after {
    background-color: transparent !important;
  }
  .react-datepicker__month {
    padding: 10px !important;
  }
}

@media screen and (min-width: 1024px) {
  .lock-scroll {
    position: relative;
    overflow: hidden;
  }
  .overflow-hidden {
    overflow: auto;
  }
  .datepickerGroupReservationContainer {
    .react-datepicker__navigation {
      top: 1rem !important;
    }
  }

  div .react-datepicker {
    display: flex;
    flex-direction: row;
    padding-top: 0;
    border-radius: 20px !important;
  }
  .react-datepicker__header {
    border-radius: 20px 20px 0 0 !important;
  }
  // .react-datepicker__day--outside-month {
  //   height: 2.7rem !important;
  // }
  .react-datepicker__navigation--next {
    right: 20px !important;
    visibility: visible;
  }
  .react-datepicker__day-names {
    display: flex;
    justify-content: center;
    gap: 10px;
    font-size: 12px !important;
  }
  .react-datepicker__navigation--previous {
    left: 20px !important;
    visibility: visible;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: #fff !important;
    color: #000 !important;
  }
  .react-datepicker__day--range-end {
    background-color: var(--booking-brand-color-2) !important;
    color: var(--booking-brand-color-1) !important;
    border-radius: 50% !important;
  }
  .react-datepicker__day--selected {
    //make background color white color black
    background-color: var(--booking-brand-color-2) !important;
    color: var(--booking-brand-color-1) !important;
    border-radius: 50% !important;
  }
}
.datepickerGroupReservation {
  padding: 20px 0px 10px 1rem !important;
  border: none !important;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  text-transform: capitalize;
  &:focus-visible {
    outline: none !important;
  }
}
/* chakra ui styles */
.chakra-checkbox__label {
  pointer-events: none;
}

/* Flip card styles for the Event Calendar module */
.flip-card {
  background-color: transparent;
  width: 100%;
  height: 100%;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:focus .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  height: 100%;
  width: 100%;
}

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
  height: 100%;
  width: 100%;
}

// Fade in on scroll enter
.hostel-container:not(:first-of-type) .hostel-row{
  opacity: 0.3;
  transition: opacity 0.3s ease-in-out;
}

.hostel-container .hostel-row.opacityFull {
  opacity: 1;
}


.akin__chat-container__button-container {
  bottom: 3% !important;
  right: 30px !important;
  left: unset !important;
  transform: translate(-50%, calc(-50% - 5px)) !important;
  z-index: 11 !important;
  border: 1.3px solid #525153 !important;

  @media (max-width: 1024px) {
    right: unset !important;
    left: 16px !important;
    transform: none !important;
    bottom: 100px !important;
  }
}